import { React } from "react";
import { Grid, Typography } from '@mui/material'
import Logo1 from '../img/LogoNuevo.jpg'
const Footer = () => {
    const footerStyle = {
         backgroundColor: '#e5e5e5',
        color: '#000000',
        padding: '20px',
        marginTop: 'auto',
    };

    const sectionStyle = {
        marginBottom: '10px',
    };

    const imageContainerStyle = {
        textAlign: 'center',
    };

    const imageStyle = {
        maxWidth: '100%',
        maxHeight: '100px', // Adjust as needed
        margin: 'auto',
    };

    return (
        <footer style={footerStyle}>
            <Grid container justify="space-between">
                <Grid item xs={12} sm={3} style={sectionStyle}>
                    <Typography variant="h6" >Contáctanos</Typography>
                    <Typography variant="body2">Teléfono: (+504) 2232-9600</Typography>
                    <Typography variant="body2">
                        Dirección: Edificio CONATEL, 6 Ave. S.O. Colonia Modelo <br />
                        Comayaguela, M.D.C. Honduras Apdo. 15012
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} style={sectionStyle}>
                </Grid>
                <Grid item xs={12} sm={3} style={sectionStyle}>
                    <div style={imageContainerStyle}>
                        <img
                            src={Logo1}
                            alt="Logo de la empresa"
                            style={imageStyle}
                        />
                    </div>
                </Grid>
            </Grid>
        </footer>
    );
}

export default Footer