import React, { useState, useEffect } from "react";
import { Box, Typography, TextField } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import FloatingButton from "../components/FloatingButton";
import f1 from "../img/fondo2.jpg"
import axios from 'axios';

const direccionConsultas = "https://api-notificaciones.conatel.gob.hn/api/BandejaHMG"
const BandejaHomologacion = () => {

    const [listadoHomologacion, setListadoHomologacion] = useState([])
    const [filtroCertificado, setFiltroCertificado] = useState([])
    const [filtroSolicitante, setFiltroSolicitante] = useState([])
    const [filtroFabricante, setFiltroFabricante] = useState([])
    const [filtroMarca, setFiltroMarca] = useState([])
    const [filtroModelo, setFiltroModelo] = useState([])
    const [filtroCertInternacional, setFiltroCertInternacional] = useState([])

    useEffect(() => {
        const ObtenerHomologaciones = async () => {
            const Datos = await axios.post(direccionConsultas + '/ObtenerHomologaciones')
            setListadoHomologacion(Datos.data)
        }

        ObtenerHomologaciones()
    }, [])

    const columns = [
        {
            field: 'NumeroCertificado',
            headerName: 'Certificado',
            flex: 2,
            headerAlign: 'left',
            align: 'left',
            className: 'Certificado',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'FechaEmision',
            headerName: 'Emisión',
            flex: 2,
            headerAlign: 'left',
            align: 'left',
            className: 'FechaEmision',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Resolucion',
            headerName: 'Resolución',
            flex: 2,
            headerAlign: 'left',
            align: 'left',
            className: 'Resolucion',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Solicitante',
            headerName: 'Solicitante',
            flex: 3,
            headerAlign: 'left',
            align: 'left',
            className: 'Solicitante',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'MarcaFabricante',
            headerName: 'Fabricante',
            flex: 2,
            headerAlign: 'left',
            align: 'left',
            className: 'Solicitante',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Marca',
            headerName: 'Marca',
            flex: 2,
            headerAlign: 'left',
            align: 'left',
            className: 'Marca',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Modelo',
            headerName: 'Modelo',
            flex: 2,
            headerAlign: 'left',
            align: 'left',
            className: 'Modelo',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'IdentificacionCertificacionInternacional',
            headerName: 'Cert. Internacional',
            flex: 2,
            headerAlign: 'left',
            align: 'left',
            className: 'Estado',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Estado',
            headerName: 'Estado',
            flex: 2,
            headerAlign: 'left',
            align: 'left',
            className: 'Estado',
            headerClassName: 'super-app-theme--header'
        }
    ]

    const filteredRows = listadoHomologacion.filter(
        (row) => {
            return (
                (filtroCertificado === '' || (row.NumeroCertificado && row.NumeroCertificado.toString().toLowerCase().includes(filtroCertificado.toString().toLowerCase()))) &&
                (filtroSolicitante === '' || (row.Solicitante && row.Solicitante.toString().toLowerCase().includes(filtroSolicitante.toString().toLowerCase()))) &&
                (filtroFabricante === '' || (row.MarcaFabricante && row.MarcaFabricante.toString().toLowerCase().includes(filtroFabricante.toString().toLowerCase()))) &&
                (filtroMarca === '' || (row.Marca && row.Marca.toString().toLowerCase().includes(filtroMarca.toString().toLowerCase()))) &&
                (filtroModelo === '' || (row.Modelo && row.Modelo.toString().toLowerCase().includes(filtroModelo.toString().toLowerCase()))) &&
                (filtroCertInternacional === '' || (row.IdentificacionCertificacionInternacional && row.IdentificacionCertificacionInternacional.toString().toLowerCase().includes(filtroCertInternacional.toString().toLowerCase())))
            );
        }
    );

    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${f1})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '100vh',
                    display: 'grid'
                }}
            >

                <Box paddingTop={4} paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center"  >
                    <Box sx={{ gridColumn: { md: "2 / span 10", xs: "span 12" } }} textAlign="center"  >
                        <Box paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" }, backgroundColor: "#67c5d78c", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }} textAlign="center"  >
                                <Typography color="black" fontSize={25} sx={{ fontWeight: 'bold', color: "white" }}  >EQUIPOS HOMOLOGADOS</Typography>
                            </Box>
                        </Box>
                        <Box paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: "span 2", textAlign: "center" }} >
                                <TextField
                                    fullWidth
                                    label="Certificado"
                                    value={filtroCertificado}
                                    onChange={(e) => setFiltroCertificado(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    style={{ marginBottom: 10 }}
                                />
                            </Box>
                            <Box sx={{ gridColumn: "span 2", textAlign: "center" }} >
                                <TextField
                                    fullWidth
                                    label="Solicitante"
                                    value={filtroSolicitante}
                                    onChange={(e) => setFiltroSolicitante(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    style={{ marginBottom: 10 }}
                                />
                            </Box>
                            <Box sx={{ gridColumn: "span 2", textAlign: "center" }} >
                                <TextField
                                    fullWidth
                                    label="Fabricante"
                                    value={filtroFabricante}
                                    onChange={(e) => setFiltroFabricante(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    style={{ marginBottom: 10 }}
                                />
                            </Box>
                            <Box sx={{ gridColumn: "span 2", textAlign: "center" }} >
                                <TextField
                                    fullWidth
                                    label="Marca"
                                    value={filtroMarca}
                                    onChange={(e) => setFiltroMarca(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    style={{ marginBottom: 10 }}
                                />
                            </Box>
                            <Box sx={{ gridColumn: "span 2", textAlign: "center" }} >
                                <TextField
                                    fullWidth
                                    label="Modelo"
                                    value={filtroModelo}
                                    onChange={(e) => setFiltroModelo(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    style={{ marginBottom: 10 }}
                                />
                            </Box>
                            <Box sx={{ gridColumn: "span 2", textAlign: "center" }} >
                                <TextField
                                    fullWidth
                                    label="Cert. Internacional"
                                    value={filtroCertInternacional}
                                    onChange={(e) => setFiltroCertInternacional(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    style={{ marginBottom: 10 }}
                                />
                            </Box>
                        </Box>
                        <Box paddingX={2} paddingBottom={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" }, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }}  >
                                <DataGrid
                                    rowHeight={30}
                                    disableRowSelectionOnClick={true}
                                    rows={filteredRows}
                                    columns={columns}
                                    getRowId={rows => rows.Id}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 25,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[25]}
                                    components={{
                                        Toolbar: GridToolbar, // Utiliza el toolbar predeterminado de Material-UI
                                    }}
                                    localeText={{ toolbarFilters: 'Buscar', toolbarDensity: 'Vista', noRowsLabel: "" }}
                                    sx={{
                                        height: "600px",
                                        '.MuiDataGrid-columnHeaderTitle': {
                                            fontWeight: 'bold',
                                            fontSize: '0.75rem'
                                        },
                                        '.MuiDataGrid-cell': {
                                            fontSize: '0.75rem',
                                            backgroundColor: "#ffffff78"
                                        },
                                        '.MuiDataGrid-filler': {
                                            display: 'none'
                                        }

                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <FloatingButton />
                </Box>
            </div>
        </>
    )
}

export default BandejaHomologacion