import React, { useState } from "react";
import { Box, TextField, Stack, Chip } from '@mui/material'
import f1 from "../img/fondo2.jpg"
import CardFormas from "../components/CardFormas"
const FormasRequisitos = () => {
    const [buscar, setBuscar] = useState('')



    const Datos = [
        {
            "id": 1,
            "Servicio": "SERVICIO TRANSMICIÓN Y CONMUTACIÓN DE DATOS A NIVEL INTERNACIONAL",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                },
                {
                    "Forma": "Forma 851",
                    "Archivo": "FORMA851.dotx"
                },
                {
                    "Forma": "Forma 300",
                    "Archivo": "FORMA300.docx"
                },
                {
                    "Forma": "Forma 500",
                    "Archivo": "FORMA500.docx"
                },
                {
                    "Forma": "Forma 900",
                    "Archivo": "FORMA900.docx"
                },
                {
                    "Forma": "Forma 920",
                    "Archivo": "FORMA920.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "Instructivo 101",
                    "Archivo": "INSTRUCTIVO101.docx"
                },
                {
                    "Instructivo": "Instructivo 300",
                    "Archivo": "INSTRUCTIVO300.docx"
                },
                {
                    "Instructivo": "Instructivo 500",
                    "Archivo": "INSTRUCTIVO500.docx"
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS TX Y CX DE DATOS A NIVEL INTERNACIONAL",
                    "Archivo": "REQUISITOSTXYCXDEDATOSANIVELINTERNACIONAL.pdf"
                },
            ]
        },
        {
            "id": 2,
            "Servicio": "CÓDIGO DE NUMERACIÓN CORTA",
            "Formas": [
                {
                    "Forma": "Forma 890",
                    "Archivo": "FORMA890.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS CÓDIGO DE NUMERACIÓN CORTA",
                    "Archivo": "REQUISITOSCODIGODENUMERACIONCORTA.pdf"
                },
            ]
        },
        {
            "id": 3,
            "Servicio": "SERVICIO DE RADIODIFUSIÓN SONORA AM",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                },
                {
                    "Forma": "Forma 202",
                    "Archivo": "FORMA202.doc"
                },
                {
                    "Forma": "Forma 204",
                    "Archivo": "FORMA204.doc"
                },
                {
                    "Forma": "Forma 910",
                    "Archivo": "FORMA910.docx"
                },
                {
                    "Forma": "Forma 930",
                    "Archivo": "FORMA930.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "Instructivo 101",
                    "Archivo": "INSTRUCTIVO101.docx"
                },
                {
                    "Instructivo": "Instructivo 201",
                    "Archivo": "INSTRUCTIVO201.docx"
                },
                {
                    "Instructivo": "Instructivo 202",
                    "Archivo": "INSTRUCTIVO202.docx"
                },
                {
                    "Instructivo": "Instructivo 204",
                    "Archivo": "INSTRUCTIVO204.docx"
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS SERVICIO DE RADIODIFUSIÓN SONORA AM",
                    "Archivo": "REQUISITOSSERVICIODERADIODIFUSIONSONORA.PDF"
                },
            ]
        },
        {
            "id": 4,
            "Servicio": "FORMULARIO DE DENUNCIAS",
            "Formas": [
                {
                    "Forma": "Forma 750",
                    "Archivo": "FORMA750.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "N/A",
                    "Archivo": ""
                },
            ]
        },
        {
            "id": 5,
            "Servicio": "SERVICIO DE RADIODIFUSIÓN SONORA FM",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                },
                {
                    "Forma": "Forma 201",
                    "Archivo": "FORMA201.doc"
                },
                {
                    "Forma": "Forma 204",
                    "Archivo": "FORMA204.doc"
                },
                {
                    "Forma": "Forma 910",
                    "Archivo": "FORMA910.docx"
                },
                {
                    "Forma": "Forma 930",
                    "Archivo": "FORMA930.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 201",
                    "Archivo": "INSTRUCTIVO201.docx"
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS SERVICIO DE RADIODIFUSIÓN SONORA FM",
                    "Archivo": "REQUISITOSSERVICIODERADIODIFUSIONSONORA.pdf"
                },
            ]
        },
        {
            "id": 6,
            "Servicio": "REQUISITOS DE SERVICIO DE RADIODIFUSIÓN DE TELEVISIÓN",
            "Formas": [
                {
                    "Forma": "Forma 203",
                    "Archivo": "FORMA203.doc"
                },
                {
                    "Forma": "Forma 203-D",
                    "Archivo": "FORMA203-D.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 203",
                    "Archivo": "INSTRUCTIVO203.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 203-D",
                    "Archivo": "INSTRUCTIVO201.docx"
                }

            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE SERVICIO DE RADIODIFUSIÓN DE TELEVISIÓN",
                    "Archivo": "REQUISITOSDESERVICIODERADIODIFUSIONDETELEVISION.pdf"
                },
            ]
        },
        {
            "id": 7,
            "Servicio": "REQUISITOS SERVICIO FIJO TERRESTRE",
            "Formas": [
                {
                    "Forma": "Forma 300",
                    "Archivo": "FORMA300.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 300",
                    "Archivo": "INSTRUCTIVO300.docx"
                }

            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS SERVICIO FIJO TERRESTRE",
                    "Archivo": "REQUISITOSSERVICIOFIJOTERRESTRE.pdf"
                },
            ]
        },
        {
            "id": 8,
            "Servicio": "SERVICIO TRANSMICIÓN Y CONMUTACIÓN",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                },
                {
                    "Forma": "Forma 850",
                    "Archivo": "FORMA850.docx"
                },
                {
                    "Forma": "Forma 300",
                    "Archivo": "FORMA300.docx"
                },
                {
                    "Forma": "Forma 500",
                    "Archivo": "FORMA500.docx"
                },
                {
                    "Forma": "Forma 900",
                    "Archivo": "FORMA900.docx"
                },
                {
                    "Forma": "Forma 920",
                    "Archivo": "FORMA920.docx"
                },

            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 101",
                    "Archivo": "INSTRUCTIVO101.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 300",
                    "Archivo": "INSTRUCTIVO300.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 500",
                    "Archivo": "INSTRUCTIVO500.docx"
                },

            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE SISTEMA TRANSMISIÓN Y CONMUTACIÓN",
                    "Archivo": "REQUISITOSDESISTEMADETRANSMISIONYCONMUTACIONDEDATOS.pdf"
                },
            ]
        },
        {
            "id": 9,
            "Servicio": "SERVICIO TELEMONITOREO",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                },
                {
                    "Forma": "Forma 600",
                    "Archivo": "FORMA600.docx"
                },
                {
                    "Forma": "Forma 820",
                    "Archivo": "FORMA820.docx"
                },
                {
                    "Forma": "Forma 900",
                    "Archivo": "FORMA900.docx"
                },
                {
                    "Forma": "Forma 920",
                    "Archivo": "FORMA920.docx"
                },

            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 101",
                    "Archivo": "INSTRUCTIVO101.docx"
                }

            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE TELEMONITOREO",
                    "Archivo": "REQUISITOSDETELEMONITOREO.pdf"
                },
            ]
        },
        {
            "id": 10,
            "Servicio": "SERVICIO DE RADIOLOCALIZACIÓN",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                },
                {
                    "Forma": "Forma 810",
                    "Archivo": "FORMA810.docx"
                },
                {
                    "Forma": "Forma 300",
                    "Archivo": "FORMA300.docx"
                },
                ,
                {
                    "Forma": "Forma 500",
                    "Archivo": "FORMA500.docx"
                },
                {
                    "Forma": "Forma 900",
                    "Archivo": "FORMA900.docx"
                },
                {
                    "Forma": "Forma 920",
                    "Archivo": "FORMA920.docx"
                },

            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 101",
                    "Archivo": "INSTRUCTIVO101.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 300",
                    "Archivo": "INSTRUCTIVO300.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 500",
                    "Archivo": "INSTRUCTIVO500.docx"
                }

            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE SISTEMA DE RADIOLOCALIZACIÓN",
                    "Archivo": "REQUISITOSDESISTEMADERADIOLOCALIZACION.pdf"
                },
            ]
        },
        {
            "id": 11,
            "Servicio": "SERVICIO REPETIDOR COMUNITARIO",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                },
                {
                    "Forma": "Forma 300",
                    "Archivo": "FORMA300.docx"
                },
                ,
                {
                    "Forma": "Forma 400",
                    "Archivo": "FORMA400.doc"
                },
                {
                    "Forma": "Forma 900",
                    "Archivo": "FORMA900.docx"
                },
                {
                    "Forma": "Forma 920",
                    "Archivo": "FORMA920.docx"
                },

            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 101",
                    "Archivo": "INSTRUCTIVO101.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 300",
                    "Archivo": "INSTRUCTIVO300.docx"
                }

            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE SERVICIO REPETIDOR COMUNITARIO",
                    "Archivo": "REQUISITOSDESERVICIOREPETIDORCOMUNITARIO.pdf"
                },
            ]
        },
        {
            "id": 12,
            "Servicio": "SERVICIO DE RADIODIFUSION DE TV COMUNITARIO",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                },
                {
                    "Forma": "Forma 203",
                    "Archivo": "FORMA203.doc"
                },
                ,
                {
                    "Forma": "Forma 203-D",
                    "Archivo": "FORMA203-D.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 101",
                    "Archivo": "INSTRUCTIVO101.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 203",
                    "Archivo": "INSTRUCTIVO203.docx"
                }

            ],
            "Requisitos": [
                {
                    "Requisito": "SERVICIO DE RADIODIFUSION DE TV COMUNITARIO",
                    "Archivo": "SERVICIODERADIODIFUSIONDETVCONFINESCOMUNITARIOS.pdf"
                },
            ]
        },
        {
            "id": 13,
            "Servicio": "CERTIFICADO HOMOLOGACION",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 870",
                    "Archivo": "FORMA870.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 870",
                    "Archivo": "INSTRUCTIVO870.doc"
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS PARA SOLICITAR CERTIFICADO HOMOLOGACION",
                    "Archivo": "REQUISITOSPARASOLICITARCERTIFICADODEHOMOLOGACIONDEEQUIPOS.pdf"
                },
            ]
        },
        {
            "id": 14,
            "Servicio": "SERVICIO RADIOAFICIONADO",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 402",
                    "Archivo": "FORMA402.doc"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 402",
                    "Archivo": "INSTRUCTIVO402.docx"
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS RADIOAFICIONADO",
                    "Archivo": "REQUISITOSRADIOAFICIONADO.pdf"
                },
            ]
        },
        {
            "id": 15,
            "Servicio": "SERVICIO TELEVISION SUSCRIPCION POR CABLE",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                },
                {
                    "Forma": "Forma 102",
                    "Archivo": "FORMA102.docx"
                },
                {
                    "Forma": "Forma 900",
                    "Archivo": "FORMA900.docx"
                },
                {
                    "Forma": "Forma 920",
                    "Archivo": "FORMA920.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 101",
                    "Archivo": "INSTRUCTIVO101.docx"
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS TELEVISION SUSCRIPCION POR CABLE",
                    "Archivo": "REQUISITOSTELEVISIONPORSUSCRIPCIONPORCABLE.pdf"
                },
            ]
        },
        {
            "id": 16,
            "Servicio": "SERVICIO DE INTERNET",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                },
                {
                    "Forma": "Forma 650",
                    "Archivo": "FORMA650.docx"
                },
                {
                    "Forma": "Forma 651",
                    "Archivo": "FORMA651.docx"
                },
                {
                    "Forma": "Forma 500",
                    "Archivo": "FORMA500.docx"
                },
                {
                    "Forma": "Forma 900",
                    "Archivo": "FORMA900.docx"
                },
                {
                    "Forma": "Forma 920",
                    "Archivo": "FORMA920.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 101",
                    "Archivo": "INSTRUCTIVO101.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 651",
                    "Archivo": "INSTRUCTIVO651.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 500",
                    "Archivo": "INSTRUCTIVO500.docx"
                },
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE SERVICIO DE INTERNET",
                    "Archivo": "REQUISITOSDESERVICIODEINTERNET.pdf"
                },
            ]
        },
        {
            "id": 17,
            "Servicio": "SERVICIO AUDIOVISUAL NACIONAL",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                },
                {
                    "Forma": "Forma 300",
                    "Archivo": "FORMA300.docx"
                },
                {
                    "Forma": "Forma 860",
                    "Archivo": "FORMA860.docx"
                },
                {
                    "Forma": "Forma 600",
                    "Archivo": "FORMA600.docx"
                },
                {
                    "Forma": "Forma 500",
                    "Archivo": "FORMA500.docx"
                },
                {
                    "Forma": "Forma 900",
                    "Archivo": "FORMA900.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 101",
                    "Archivo": "INSTRUCTIVO101.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 300",
                    "Archivo": "INSTRUCTIVO300.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 860",
                    "Archivo": "INSTRUCTIVO860.docx"
                },
                ,
                {
                    "Instructivo": "INSTRUCTIVO 600",
                    "Archivo": "INSTRUCTIVO600.docx"
                },
                ,
                {
                    "Instructivo": "INSTRUCTIVO 500",
                    "Archivo": "INSTRUCTIVO500.docx"
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE SERVICIO AUDIOVISUAL NACIONAL",
                    "Archivo": "REQUISITOSDESERVICIOAUDIOVISUALNACIONA.pdf"
                },
            ]
        },
        {
            "id": 17,
            "Servicio": "SERVICIO AUDIOVISUAL NACIONAL",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                },
                {
                    "Forma": "Forma 300",
                    "Archivo": "FORMA300.docx"
                },
                {
                    "Forma": "Forma 860",
                    "Archivo": "FORMA860.docx"
                },
                {
                    "Forma": "Forma 600",
                    "Archivo": "FORMA600.docx"
                },
                {
                    "Forma": "Forma 500",
                    "Archivo": "FORMA500.docx"
                },
                {
                    "Forma": "Forma 900",
                    "Archivo": "FORMA900.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 101",
                    "Archivo": "INSTRUCTIVO101.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 300",
                    "Archivo": "INSTRUCTIVO300.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 860",
                    "Archivo": "INSTRUCTIVO860.docx"
                },
                ,
                {
                    "Instructivo": "INSTRUCTIVO 600",
                    "Archivo": "INSTRUCTIVO600.docx"
                },
                ,
                {
                    "Instructivo": "INSTRUCTIVO 500",
                    "Archivo": "INSTRUCTIVO500.docx"
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE SERVICIO AUDIOVISUAL NACIONAL",
                    "Archivo": "REQUISITOSDESERVICIOAUDIOVISUALNACIONA.pdf"
                },
            ]
        },
        {
            "id": 18,
            "Servicio": "SERVICIO RADIODIFUSIÓN SONORA COMUNITARIO",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                },
                {
                    "Forma": "Forma 201",
                    "Archivo": "FORMA201.doc"
                },
                {
                    "Forma": "Forma 202",
                    "Archivo": "FORMA202.doc"
                },
                {
                    "Forma": "Forma 204",
                    "Archivo": "FORMA204.doc"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 101",
                    "Archivo": "INSTRUCTIVO101.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 201",
                    "Archivo": "INSTRUCTIVO201.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 202",
                    "Archivo": "INSTRUCTIVO202.docx"
                },
                ,
                {
                    "Instructivo": "INSTRUCTIVO 204",
                    "Archivo": "INSTRUCTIVO204.docx"
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE SERVICIO RADIODIFUSIÓN SONORA COMUNITARIO",
                    "Archivo": "REQUISITOSDESERVICIORADIODIFUSIONSONORA(FM).pdf"
                },
            ]
        },
        {
            "id": 19,
            "Servicio": "SERVICIO DE TELEVISIÓN ALAMBRICO",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                },
                {
                    "Forma": "Forma 102",
                    "Archivo": "FORMA102.docx"
                },
                {
                    "Forma": "Forma 910",
                    "Archivo": "FORMA910.docx"
                },
                {
                    "Forma": "Forma 930",
                    "Archivo": "FORMA930.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 101",
                    "Archivo": "INSTRUCTIVO101.docx"
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS SERVICIO DE TELEVISIÓN INALÁMBRICO",
                    "Archivo": "REQUISITOSSERVICIODETVPORSUSCRIPCIONPORMEDIOSINALAMBRICOS.pdf"
                },
            ]
        },
        {
            "id": 20,
            "Servicio": "SERVICIO DE PROVEEDORES DE ACCESO",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE SERVICIO DE PROVEEDORES DE ACCESO",
                    "Archivo": "REQUISITOSDESERVICIOPACI.pdf"
                },
            ]
        },
        {
            "id": 21,
            "Servicio": "SERVICIO MÓVIL AERONÁUTICO",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 600",
                    "Archivo": "FORMA600.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 600",
                    "Archivo": "INSTRUCTIVO600.docx"
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE SERVICIO MÓVIL AERONÁUTICO",
                    "Archivo": "REQUISITOSDESERVICIOMOVILAERONAUTICO.pdf"
                },
            ]
        },
        {
            "id": 22,
            "Servicio": "DENUNCIAS",
            "Formas": [
                {
                    "Forma": "Forma 700",
                    "Archivo": "FORMA700.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 700",
                    "Archivo": "INSTRUCTIVO700.docx"
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE SERVICIO MÓVIL AERONÁUTICO",
                    "Archivo": "REQUISITOSDEDENUNCIASPORINTERFERENCIA.pdf"
                },
            ]
        },
        {
            "id": 23,
            "Servicio": "LICENCIA DEL SERVICIO MOVIL AERONAUTICO EN AERONAVE",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 950",
                    "Archivo": "FORMA950.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 950",
                    "Archivo": "INSTRUCTIVO950.docx"
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS LICENCIA DEL SERVICIO MOVIL AERONAUTICO EN AERONAVE",
                    "Archivo": "REQUISITOSLICENCIADELSERVICIOMOVILAERONAUTICOENAERONAVE.pdf"
                },
            ]
        },
        {
            "id": 24,
            "Servicio": "CONSOLIDACIÓN",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE CONSOLIDACIÓN",
                    "Archivo": "REQUISITOSDECONSOLIDACIONDEDEUDAYARREGLODEPAGO.pdf"
                },
            ]
        },
        {
            "id": 25,
            "Servicio": "CONVENIO SATELITAL",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS PARA UN CONVENIO SATELITAL",
                    "Archivo": "REQUISITOSPARAUNCONVENIOSATELITAL.pdf"
                },
            ]
        },
        {
            "id": 25,
            "Servicio": "PRÓRROGA PARA INICIO",
            "Formas": [
                {
                    "Forma": "N/A",
                    "Archivo": ""
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS PRÓRROGA PARA INICIO",
                    "Archivo": "REQUISITOSPRORROGAPARAINICIODEOPERACIONES.pdf"
                },
            ]
        },
        {
            "id": 26,
            "Servicio": "REGISTRO DE PUNTO DE ACTIVACIÓN DE SERVICIO (PSA) EN EL SERVICIO MÓVIL MARÍTIMO.",
            "Formas": [
                {
                    "Forma": "N/A",
                    "Archivo": ""
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE AUTORIDADES CONTABLES",
                    "Archivo": "REGISTRODEPADESERVICIOPSAENELSERVICIOMM.pdf"
                },
            ]
        },
        {
            "id": 27,
            "Servicio": "TARIFA ESPECIAL",
            "Formas": [
                {
                    "Forma": "N/A",
                    "Archivo": ""
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS TARIFA ESPECIAL",
                    "Archivo": "REQUISITOSTARIFAESPECIAL.pdf"
                },
            ]
        },
        {
            "id": 28,
            "Servicio": "SERVICIO (PCI)",
            "Formas": [
                {
                    "Forma": "N/A",
                    "Archivo": ""
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS SERVICIO (PCI)",
                    "Archivo": "REQUISITOSSERVICIO(PCI).pdf"
                },
            ]
        },
        {
            "id": 29,
            "Servicio": "SERVICIO INSCRIPCION DE CIBER CAFÉ",
            "Formas": [
                {
                    "Forma": "N/A",
                    "Archivo": ""
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE INSCRIPCION DE CIBER CAFÉ",
                    "Archivo": "REQUISITOSDEINSCRIPCIONDECIBERCAFE.pdf"
                },
            ]
        },
        {
            "id": 30,
            "Servicio": "REGISTRO PARA COMERCIALIZADOR",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS REGISTRO PARA COMERCIALIZADOR",
                    "Archivo": "REQUISITOSREGISTROPARACOMERCIALIZADOR.pdf"
                },
            ]
        },
        {
            "id": 31,
            "Servicio": "SERVICIO CENTRO ATENCION DE LLAMADAS",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                },
                {
                    "Forma": "Forma 880",
                    "Archivo": "FORMA880.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 101",
                    "Archivo": "INSTRUCTIVO101.docx"
                },
                {
                    "Instructivo": "INSTRUCTIVO 880",
                    "Archivo": "INSTRUCTIVO880.doc"
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS CENTRO ATENCION DE LLAMADAS",
                    "Archivo": "REQUISITOSCENTRODEATENCIONDELLAMADAS.pdf"
                },
            ]
        },
        {
            "id": 32,
            "Servicio": "TRANSFERENCIA DE DERECHOS",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 900",
                    "Archivo": "FORMA900.docx"
                },
                {
                    "Forma": "Forma 920",
                    "Archivo": "FORMA920.docx"
                },
                {
                    "Forma": "Forma 910",
                    "Archivo": "FORMA910.docx"
                },
                {
                    "Forma": "Forma 930",
                    "Archivo": "FORMA930.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE TRANSFERENCIA DE DERECHOS",
                    "Archivo": "REQUISITOSDETRANSFERENCIADEDERECHOS.pdf"
                },
            ]
        },
        {
            "id": 33,
            "Servicio": "SERVICIO CENTRO DE TERCERIZACIÓN",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITO CENTRO DE TERCERIZACIÓN",
                    "Archivo": "REQUISITOCENTRODETERCERIZACION.pdf"
                },
            ]
        },
        {
            "id": 34,
            "Servicio": "SERVICIO COMERCIALIZADOR TIPO REVENDEDOR",
            "Formas": [
                {
                    "Forma": "Forma 100",
                    "Archivo": "FORMA100.docx"
                },
                {
                    "Forma": "Forma 101",
                    "Archivo": "FORMA101.docx"
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "INSTRUCTIVO 101",
                    "Archivo": "INSTRUCTIVO101.docx"
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE COMERCIALIZADOR TIPO REVENDEDOR",
                    "Archivo": "REQUISITOSDECOMERCIALIZADORTIPOREVENDEDOR.pdf"
                },
            ]
        },
        ,
        {
            "id": 35,
            "Servicio": "REGISTRO DE AUTORIDADES ENCARGADAS DE LA CONTABILIDAD (AAIC) EN EL SERVICIO MÓVIL MARÍTIMO.",
            "Formas": [
                {
                    "Forma": "N/A",
                    "Archivo": ""
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REGISTRO DE AUTORIDADES ENCARGADAS DE LA CONTABILIDAD (AAIC) EN EL SERVICIO MÓVIL MARÍTIMO.",
                    "Archivo": "REGAUTORIDADESENCARGADAS(AAIC)ENMM.pdf"
                },
            ]
        },
        {
            "id": 36,
            "Servicio": "LICENCIAS DEL SERVICIO MÓVIL MARÍTIMO EN EMBARCACIONES",
            "Formas": [
                {
                    "Forma": "N/A",
                    "Archivo": ""
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "LICENCIAS DEL SERVICIO MÓVIL MARÍTIMO EN EMBARCACIONES",
                    "Archivo": "REQUISITOSDEMMENEMBARCACIONES.pdf"
                },
            ]
        },
        {
            "id": 37,
            "Servicio": "PERMISO Y LICENCIAS DEL SERVICIO MÓVIL MARÍTIMO PARA ESTACIONES COSTERAS",
            "Formas": [
                {
                    "Forma": "N/A",
                    "Archivo": ""
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "PERMISO Y LICENCIAS DEL SERVICIO MÓVIL MARÍTIMO PARA ESTACIONES COSTERAS",
                    "Archivo": "REQUISITOSPERMISOYLICENCIASDELSERVICIOMMPARAESTACIONESCOSTERAS.pdf"
                },
            ]
        },
        {
            "id": 38,
            "Servicio": "SOLICITUDES VARIAS",
            "Formas": [
                {
                    "Forma": "N/A",
                    "Archivo": ""
                }
            ],
            "Instructivos": [
                {
                    "Instructivo": "N/A",
                    "Archivo": ""
                }
            ],
            "Requisitos": [
                {
                    "Requisito": "REQUISITOS DE SOLICITUDES VARIAS",
                    "Archivo": "REQUISITOSDESOLICITUDESVARIAS.pdf"
                },
            ]
        }
    ]

    const filteredDatos = Datos.filter((row) =>
        row.Servicio.toLowerCase().includes(buscar.toLowerCase())
    );

    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${f1})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '100vh'
                }}
            >
                <Box paddingTop={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                    <Box sx={{ gridColumn: "span 12" }} >
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" >
                            <Chip
                                label="¡Presione la forma, instructivo o requisito para descargarlo!"
                                variant="outlined"
                                sx={{ fontSize: { md: "1em", xs: "0.35em" }, backgroundColor: '#ffff', fontWeight: "bold" }}
                                className="animate__animated animate__pulse animate__infinite"
                            />
                        </Stack>
                    </Box>
                </Box>
                <Box padding={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                    <Box sx={{ gridColumn: "span 4" }} >
                        <TextField
                            id="standard-search"
                            label="Buscar servicio"
                            type="search"
                            variant="standard"
                            fullWidth
                            onChange={(e) => setBuscar(e.target.value)}
                        />
                    </Box>
                </Box>
                <Box paddingTop={2} paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" sx={{
                    maxHeight: '620px', 
                    overflowY: 'scroll',  
                    '&::-webkit-scrollbar': {
                        width: 0,  
                    },
                    scrollbarWidth: 'none',  
                    msOverflowStyle: 'none',  
                }}  >
                    {
                        filteredDatos.map((row, index) => (
                            <Box key={index} sx={{ gridColumn: { md: "span 4", xs: "span 12" } }} >
                                <CardFormas tittle={row.Servicio} forma={row.Formas} instructivo={row.Instructivos} requisito={row.Requisitos} />
                            </Box>
                        ))
                    }
                </Box>
                <br />
            </div>
        </>
    )
}
export default FormasRequisitos