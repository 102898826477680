import { Box, Avatar, Stack, Typography } from "@mui/material";
import { useLocation } from 'react-router-dom';
import React from "react";
import Logo1 from '../img/LogoNuevo.jpg';

const Topbar = () => {
  const location = useLocation();
  let Titulo = ""
  switch (location.pathname) {
    case "/BandejaRadioaficionado": Titulo = "LISTADO DE RADIOAFICIONADOS"
      break;
    case "/BandejaNotificacion": Titulo = "NOTIFICACIONES ELECTRÓNICAS"
      break;
    case "/BandejaAC": Titulo = "AUTORIDADES CONTABLES"
      break;
    case "/BandejaHM": Titulo = "HOMOLOGACIONES"
      break;
    case "/FormasRequisitos": Titulo = "FORMAS Y REQUISITOS"
      break;
    default: Titulo = ""
      break;
  }

  return (
    <Box sx={{ background: '#e5e5e5' }} display="flex" justifyContent="space-between" alignItems="center" p={0.5}>
      <Box
        display="flex"
        borderRadius="3px"
        justifyContent="left"
        alignItems="center"
        sx={{ flex: 1 }}
      >
        <Stack direction="row" spacing={2}>
          <Avatar variant="square" sx={{ verticalAlign: 'middle', width: { md: "60%", xs: "50%" }, height: 'auto' }} src={Logo1} />
        </Stack>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ flex: 2 }}
      >
        <Typography variant="h4" component="div" fontFamily="unset" sx={{ fontWeight: 'bold', color: "#67c5d7", '-webkit-text-stroke': '0.1px black' }}>
          {Titulo}
        </Typography>
      </Box>
      <Box
        display="flex"
        borderRadius="3px"
        justifyContent="right"
        alignItems="center"
        sx={{ flex: 1 }}
      >
      </Box>
    </Box>
  );
};

export default Topbar;