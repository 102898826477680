
import React, { useState, useEffect } from "react";
import { Box, Typography, TextField } from '@mui/material'
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid'
import f1 from "../img/fondo2.jpg"
import axios from 'axios';

const BandejaRadioaficionado = () => {
     const direccionConsultas = "https://api-notificaciones.conatel.gob.hn/api/BandejaRadioaficionado"

    const [listadoRadioaficionado, setListadoRadioaficionado] = useState([])
    const [listadoXresolucion, setListadoXresolucion] = useState([])
    const [filtroRadioaficionado, setfiltroRadioaficionado] = useState('');
    const [filtroXresolucion, setfiltroXresolucion] = useState('');

    const handlefiltroRadioaficionadoChange = (event) => {
        setfiltroRadioaficionado(event.target.value);
    };
    const handlefiltroXresolucionChange = (event) => {
        setfiltroXresolucion(event.target.value);
    };

    const filteredRowsRadioaficionado = listadoRadioaficionado.filter(
        (row) => {
            const filtro = filtroRadioaficionado.toString().toLowerCase();
            return (
                filtroRadioaficionado === '' ||
                (row.Operador && row.Operador.toString().toLowerCase().includes(filtro)) ||
                (row.IndicativoLlamada && row.IndicativoLlamada.toString().toLowerCase().includes(filtro)) 

            );
        }
    );

    const filteredRowsXresolucion = listadoXresolucion.filter(
        (row) => {
            const filtro = filtroXresolucion.toString().toLowerCase();
            return (
                filtroXresolucion === '' ||
                (row.Operador && row.Operador.toString().toLowerCase().includes(filtro)) ||
                (row.Indicativo && row.Indicativo.toString().toLowerCase().includes(filtro)) 

            );
        }
    );

    const columns = [
        {
            field: 'Operador',
            headerName: 'Operador',
            flex: 3,
            headerAlign: 'center',
            align: 'center',
            className: 'Expediente',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'IndicativoLlamada',
            headerName: 'Indicativo de llamada',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            className: 'SistemaFecha',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Categoria',
            headerName: 'Categoría',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            className: 'FechaLimite',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'FechaVencimiento',
            headerName: 'Fecha de Vencimiento',
            flex: 3,
            headerAlign: 'center',
            align: 'center',
            className: 'Abogado',
            headerClassName: 'super-app-theme--header'
        }
    ]

    const columns2 = [
        {
            field: 'Operador',
            headerName: 'Operador',
            flex: 3,
            headerAlign: 'center',
            align: 'center',
            className: 'Expediente',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Indicativo',
            headerName: 'Indicativo de llamada',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            className: 'SistemaFecha',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Resolucion',
            headerName: 'Resolución/Licencia',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            className: 'FechaLimite',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Vigencia',
            headerName: 'Fecha de Vencimiento',
            flex: 3,
            headerAlign: 'center',
            align: 'center',
            className: 'Abogado',
            headerClassName: 'super-app-theme--header'
        }
    ]

    useEffect(() => {
        const ListadoRadioaficionado = async () => {
            const Datos = await axios.post(direccionConsultas + '/ObtenerRadioaficionados')
            setListadoRadioaficionado(Datos.data)
        }
        const ListadoXresolucion = async () => {
            const Datos = await axios.post(direccionConsultas + '/ObtenerMedianteResolucion')
            setListadoXresolucion(Datos.data)
        }

        ListadoXresolucion()
        ListadoRadioaficionado()
    }, [])

    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${f1})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '100vh',
                    display: 'grid'
                }}
            >
                <Box paddingTop={8} paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center"  >
                    <Box sx={{ gridColumn: { md: "span 6", xs: "span 12" } }} textAlign="center"  >
                        <Box paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" }, backgroundColor: "#67c5d78c", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }} textAlign="center"  >
                                <Typography color="black" fontSize={25} sx={{ fontWeight: 'bold', color: "white" }}  >RADIOAFICIONADOS</Typography>
                            </Box>
                        </Box>
                        <Box paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                <TextField
                                    fullWidth
                                    label="Buscar"
                                    value={filtroRadioaficionado}
                                    onChange={handlefiltroRadioaficionadoChange}
                                    variant="standard"
                                    size="small"
                                    style={{ marginBottom: 10 }}
                                />
                            </Box>
                        </Box>
                        <Box paddingX={2} paddingBottom={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" }, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }}  >
                                <DataGrid
                                    rowHeight={20}
                                    disableRowSelectionOnClick={true}
                                    rows={filteredRowsRadioaficionado}
                                    columns={columns}
                                    getRowId={rows => rows.Id}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 20,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[15]}
                                    components={{
                                        Toolbar: GridToolbar, // Utiliza el toolbar predeterminado de Material-UI
                                    }}
                                    localeText={{ toolbarFilters: 'Buscar', toolbarDensity: 'Vista', noRowsLabel: "" }}
                                    sx={{
                                        height: "512px",
                                        '.MuiDataGrid-columnHeaderTitle': {
                                            fontWeight: 'bold',
                                            fontSize: '0.75rem'
                                        },
                                        '.MuiDataGrid-cell': {
                                            fontSize: '0.75rem',
                                            backgroundColor: "#ffffff78"
                                        },
                                        '.MuiDataGrid-filler': {
                                            display: 'none'
                                        }
                                        
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ gridColumn: { md: "span 6", xs: "span 12" } }} textAlign="center"  >
                        <Box paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" }, backgroundColor: "#67c5d78c", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }} textAlign="center"  >
                                <Typography color="black" fontSize={25} sx={{ fontWeight: 'bold', color: "white" }}  >RADIOAFICIONADOS MEDIANTE RESOLUCIÓN</Typography>
                            </Box>
                        </Box>
                        <Box paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                <TextField
                                    fullWidth
                                    label="Buscar"
                                    value={filtroXresolucion}
                                    onChange={handlefiltroXresolucionChange}
                                    variant="standard"
                                    size="small"
                                    style={{ marginBottom: 10 }}
                                />
                            </Box>
                        </Box>
                        <Box paddingX={2} paddingBottom={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" }, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }}  >
                                <DataGrid
                                    rowHeight={20}
                                    disableRowSelectionOnClick={true}
                                    rows={filteredRowsXresolucion}
                                    columns={columns2}
                                    getRowId={rows => rows.ID}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 20,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[20]}
                                    components={{
                                        Toolbar: GridToolbar, // Utiliza el toolbar predeterminado de Material-UI
                                    }}
                                    localeText={{ toolbarFilters: 'Buscar', toolbarDensity: 'Vista', noRowsLabel: "" }}
                                    sx={{
                                        height: "512px",
                                        '.MuiDataGrid-columnHeaderTitle': {
                                            fontWeight: 'bold',
                                            fontSize: '0.75rem'
                                        },
                                        '.MuiDataGrid-cell': {
                                            fontSize: '0.75rem',
                                            backgroundColor: "#ffffff78"
                                        },
                                        '.MuiDataGrid-filler': {
                                            display: 'none'
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </>
    )
}

export default BandejaRadioaficionado