
import React, { useState } from "react";
import { Box, Fab, Tooltip } from '@mui/material'
import Forma from '../img/forma.png';
import Requisito from '../img/requisito.png';
import Documento from '../img/documento.png';
import 'animate.css';

const FloatingButton = () => {
    const [showButtons, setShowButtons] = useState(false);

    const handleMouseEnter = () => {
        setShowButtons(true);
    };

    const handleMouseLeave = () => {
        setShowButtons(false);
    };

    const style = {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        backgroundColor: "#56bcd7"
    };
    const style2 = {
        margin: 0,
        top: 'auto',
        right: 30,
        bottom: 85,
        left: 'auto',
        position: 'fixed',
    };
    const style3 = {
        margin: 0,
        top: 'auto',
        right: 30,
        bottom: 135,
        left: 'auto',
        position: 'fixed',
    };

    const handleFormaClick = (event) => {
        event.stopPropagation();
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/files/FORMA870.docx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleRequisitosClick = (event) => {
        event.stopPropagation();
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/files/Requisitos.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <Fab className="animate__animated animate__pulse animate__infinite" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={style} aria-label="add">
                <img src={Documento} alt="add" style={{ width: '100%', height: '100%' }} />
            </Fab>
            <Box
                sx={{ visibility: showButtons ? 'visible' : 'hidden', transition: 'visibility 0.1s, opacity 0.5s linear', }}
            >
                <Tooltip title="Forma 870" placement="left-start">
                    <Fab onClick={handleFormaClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={style2} size="small" color="secundary" aria-label="add">
                        <img src={Forma} alt="add" style={{ width: '100%', height: '100%' }} />
                    </Fab>
                </Tooltip>
                <Tooltip title="Requisitos" placement="left-start">
                    <Fab onClick={handleRequisitosClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={style3} size="small" color="secundary" aria-label="add">
                        <img src={Requisito} alt="add" style={{ width: '100%', height: '100%' }} />
                    </Fab>
                </Tooltip>
            </Box>
        </>
    )
}

export default FloatingButton