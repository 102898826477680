
import React from "react";
import { Box, Typography } from '@mui/material'

const CardFormas = ({ tittle, forma = [], instructivo = [], requisito = [] }) => {
    const boxStyles = {
        height: "200px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
        backgroundColor: "#67c5d729",
        backgroundSize: 'cover',
        borderRadius: "16px",
        transition: "transform 0.3s, box-shadow 0.3s",
        '&:hover': {
            transform: "translateY(-10px)",
            boxShadow: "0px 15px 25px rgba(0, 0, 0, 0.6)"
        },
        gridColumn: { md: "span 12", xs: "span 12" }
    };

    const handleFormaClick = (event, fileName) => {
        event.stopPropagation();
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/files/${fileName}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <Box sx={{ ...boxStyles  }}>
                <Box paddingX={1} paddingTop={1} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center">
                    <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" } }} textAlign="center">
                        <Typography color="black" fontSize={16} sx={{fontWeight: "bold", color: "#205981" }}>{tittle}</Typography>
                    </Box>
                </Box>
                <hr />
                <Box paddingX={1} paddingBottom={1} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))">
                    <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" } }}>
                        <Typography fontSize={16} sx={{fontWeight: "bold", color: "black" }}>
                        ■ Formas:
                            {
                                forma.map((row, index) => (
                                    <span key={index}>
                                          <a style={{ fontSize: "small", color: "black", cursor: "pointer" }} onClick={(event) => handleFormaClick(event, `${row.Archivo}`)}>
                                            {" " + row.Forma}
                                        </a>
                                        {index < forma.length - 1 && ","}  
                                        
                                    </span>
                                ))
                            }
                        </Typography>
                    </Box>
                </Box>
                <Box paddingX={1} paddingBottom={1} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))">
                    <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" } }}>
                        <Typography fontSize={16} sx={{fontWeight: "bold", color: "black" }}>
                            ■ Instructivo:
                            {
                                instructivo.map((row, index) => (
                                    <span key={index}>
                                         <a style={{ fontSize: "small", color: "black", cursor: "pointer" }} onClick={(event) => handleFormaClick(event, `${row.Archivo}`)}>
                                            {" " + row.Instructivo}
                                        </a>
                                        {index < instructivo.length - 1 && ","} 
                                        
                                    </span>
                                ))
                            }
                        </Typography>
                    </Box>
                </Box>
                <Box paddingX={1} paddingBottom={1} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))">
                    <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" } }}>
                        <Typography fontSize={16} sx={{fontWeight: "bold", color: "black" }}>
                        ■ Requisito:
                            {
                                requisito.map((row, index) => (
                                    <a key={index} style={{ fontSize: "small", color: "black", cursor: "pointer" }} onClick={(event) => handleFormaClick(event, `${row.Archivo}`)}> {" " + row.Requisito}</a>
                                ))
                            }
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default CardFormas