
import React, { useState, useEffect } from "react";
import { Box, Typography, TextField } from '@mui/material'
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid'
import DownloadIcon from '@mui/icons-material/Download';
import f1 from "../img/fondo2.jpg"
import axios from 'axios';

const BandejaNotificacion = () => {

    const direccionConsultas = "https://api-notificaciones.conatel.gob.hn/api/Bandeja"
    const direccionConsultas2 = "https://api-notificaciones.conatel.gob.hn/api/Notificaciones"


    const [providencias, setProvidencias] = useState([])
    const [resoluciones, setResoluciones] = useState([])
    const [filtroProvidencia, setfiltroProvidencia] = useState('');
    const [filtroResolucion, setfiltroResolucion] = useState('');

    const handlefiltroProvidenciaChange = (event) => {
        setfiltroProvidencia(event.target.value);
    };

    const handlefiltroResolucionChange = (event) => {
        setfiltroResolucion(event.target.value);
    };

    const filteredRowsProvidencias = providencias.filter(
        (row) => {
            const filtro = filtroProvidencia.toString().toLowerCase();
            return (
                filtroProvidencia === '' ||
                (row.Expediente && row.Expediente.toString().toLowerCase().includes(filtro)) ||
                (row.Abogado && row.Abogado.toString().toLowerCase().includes(filtro)) ||
                (row.CodigoNotificacion && row.CodigoNotificacion.toString().toLowerCase().includes(filtro))

            );
        }
    );
    const filteredRowsResolucion = resoluciones.filter(
        (row) => {
            const filtro = filtroResolucion.toString().toLowerCase();
            return (
                filtroResolucion === '' ||
                (row.Expediente && row.Expediente.toString().toLowerCase().includes(filtro)) ||
                (row.Abogado && row.Abogado.toString().toLowerCase().includes(filtro)) ||
                (row.CodigoNotificacion && row.CodigoNotificacion.toString().toLowerCase().includes(filtro))

            );
        }
    );

    useEffect(() => {
        const ListadoProvidencia = async () => {
            const Datos = await axios.post(direccionConsultas + '/ObtenerProvidencias')
            setProvidencias(Datos.data)
        }

        const ListadoResolucion = async () => {
            const Datos = await axios.post(direccionConsultas + '/ObtenerResoluciones')
            setResoluciones(Datos.data)
        }
        ListadoResolucion()
        ListadoProvidencia()
    }, [])

    const openPopup = (url) => {
        window.open(url, '_blank', 'width=800,height=600');
    };

    const descargarArchivo = async (CodigoNotificacion, NombreArchivo, Tipo) => {
        try {
            const response = await axios.get(direccionConsultas2 + '/ObtenerArchivo?not=' + CodigoNotificacion + '&T=' + Tipo + '', {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', NombreArchivo);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    };

    const columns = [
        {
            field: 'action',
            headerName: 'No.',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const currentRow = params.row;
                return (
                    <a href="#" onClick={() => openPopup(`https://app-notificaciones.conatel.gob.hn/verNotificacion?T=1&N=${currentRow.CodigoNotificacion}`)}>
                        {currentRow.CodigoNotificacion}
                    </a>
                );
            },
        },
        {
            field: 'Expediente',
            headerName: 'Expediente',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            className: 'Expediente',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'FechaNotificacion',
            headerName: 'Fecha Notificación',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            className: 'SistemaFecha',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'FechaLimite',
            headerName: 'Fecha Limite',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            className: 'FechaLimite',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Abogado',
            headerName: 'Abogado',
            flex: 3,
            headerAlign: 'center',
            align: 'center',
            className: 'Abogado',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'action2',
            headerName: 'Descargar',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = (e) => {
                    const currentRow = params.row;
                    descargarArchivo(currentRow.CodigoNotificacion, currentRow.ImagenNombre, 1)
                };
                return (
                    <GridActionsCellItem onClick={onClick} icon={<DownloadIcon />} label="" style={{
                        padding: "0px",
                        color: "#67c5d7",
                    }}></GridActionsCellItem>
                );
            }
        },
    ]
    const columns2 = [
        {
            field: 'action',
            headerName: 'No.',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const currentRow = params.row;
                return (
                    <a href="#" onClick={() => openPopup(`https://app-notificaciones.conatel.gob.hn/verNotificacion?T=2&N=${currentRow.CodigoNotificacion}`)}>
                        {currentRow.CodigoNotificacion}
                    </a>
                );
            },
        },
        {
            field: 'Expediente',
            headerName: 'Expediente',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            className: 'Expediente',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'FechaNotificacion',
            headerName: 'Fecha Notificación',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            className: 'FechaNotificacion',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'FechaLimite',
            headerName: 'Fecha Limite',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            className: 'FechaLimite',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Abogado',
            headerName: 'Abogado',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            className: 'Abogado',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'action2',
            headerName: 'Descargar',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = (e) => {
                    const currentRow = params.row;
                    descargarArchivo(currentRow.CodigoNotificacion, currentRow.ImagenNombre, 2)
                };
                return (
                    <GridActionsCellItem onClick={onClick} icon={<DownloadIcon />} label="" style={{
                        padding: "0px",
                        color: "#67c5d7",
                    }}></GridActionsCellItem>
                );
            }
        },
    ]

    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${f1})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '100vh',
                    display: 'grid'
                }}
            >
                <Box paddingTop={8} paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center"  >
                    <Box sx={{ gridColumn: { md: "span 6", xs: "span 12" } }} textAlign="center"  >
                        <Box  paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" }, backgroundColor: "#67c5d78c",  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }} textAlign="center"  >
                                <Typography color="black" fontSize={25} sx={{ fontWeight: 'bold', color: "white" }}  >Notificaciones Providencia</Typography>
                            </Box>
                        </Box>
                        <Box paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                <TextField
                                    fullWidth
                                    label="Buscar"
                                    value={filtroProvidencia}
                                    onChange={handlefiltroProvidenciaChange}
                                    variant="standard"
                                    size="small"
                                    style={{ marginBottom: 10 }}
                                />
                            </Box>
                        </Box>
                        <Box  paddingX={2} paddingBottom={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" }, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }}  >
                                <DataGrid
                                    rowHeight={20}
                                    disableRowSelectionOnClick={true}
                                    rows={filteredRowsProvidencias}
                                    columns={columns}
                                    getRowId={rows => rows.Id}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 20,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[15]}
                                    components={{
                                        Toolbar: GridToolbar, // Utiliza el toolbar predeterminado de Material-UI
                                    }}
                                    localeText={{ toolbarFilters: 'Buscar', toolbarDensity: 'Vista', noRowsLabel: "" }}
                                    sx={{
                                        height: "512px",
                                        '.MuiDataGrid-columnHeaderTitle': {
                                            fontWeight: 'bold',
                                            fontSize: '0.75rem'
                                        },
                                        '.MuiDataGrid-cell': {
                                            fontSize: '0.75rem',
                                            backgroundColor:"#ffffff78"
                                        },
                                        '.MuiDataGrid-filler': {
                                            display: 'none'
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ gridColumn: { md: "span 6", xs: "span 12" } }} textAlign="center"  >
                        <Box paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" }, backgroundColor: "#67c5d78c",  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }} textAlign="center"  >
                                <Typography color="black" fontSize={25} sx={{ fontWeight: 'bold', color: "white" }} >Notificaciones Resolución</Typography>
                            </Box>
                        </Box>
                        <Box paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                <TextField
                                    fullWidth
                                    label="Buscar"
                                    value={filtroResolucion}
                                    onChange={handlefiltroResolucionChange}
                                    variant="standard"
                                    size="small"
                                    style={{ marginBottom: 10 }}
                                />
                            </Box>
                        </Box>
                        <Box paddingX={2} paddingBottom={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" }, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }}  >
                                <DataGrid
                                    rowHeight={20}
                                    disableRowSelectionOnClick={true}
                                    rows={filteredRowsResolucion}
                                    columns={columns2}
                                    getRowId={rows => rows.Id}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 20,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[15]}
                                    components={{
                                        Toolbar: GridToolbar, // Utiliza el toolbar predeterminado de Material-UI
                                    }}
                                    localeText={{ toolbarFilters: 'Buscar', toolbarDensity: 'Vista', noRowsLabel: "" }}
                                    sx={{
                                        height: "512px",
                                        '.MuiDataGrid-columnHeaderTitle': {
                                            fontWeight: 'bold',
                                            fontSize: '0.75rem',
                                        },
                                        '.MuiDataGrid-cell': {
                                            fontSize: '0.75rem',
                                             backgroundColor:"#ffffff78"
                                        },
                                        '.MuiDataGrid-filler': {
                                            display: 'none'
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </>
    )
}

export default BandejaNotificacion