import React, { useState, useEffect } from "react";
import { Box, Typography, TextField } from '@mui/material'
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid'
import f1 from "../img/fondo2.jpg"
import axios from 'axios';

const direccionConsultas = "https://api-notificaciones.conatel.gob.hn/api/BandejaAC"
const BandejaAC = () => {
    const [listadoAutoridades, setListadoAutoridades] = useState([])

    useEffect(() => {
        const ObtenerAC = async () => {
            const Datos = await axios.post(direccionConsultas + '/ObtenerAC')
            setListadoAutoridades(Datos.data)
        }

        ObtenerAC()
    }, [])

    const columns = [
        {
            field: 'Codigo',
            headerName: 'Codigo Operador',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            className: 'Expediente',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Operador',
            headerName: 'Operador',
            flex: 3,
            headerAlign: 'center',
            align: 'center',
            className: 'Operador',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'Denominacion',
            headerName: 'Denominación',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            className: 'Denominacion',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'FechaVigencia',
            headerName: 'Fecha de Vencimiento',
            flex: 3,
            headerAlign: 'center',
            align: 'center',
            className: 'FechaVidencia',
            headerClassName: 'super-app-theme--header'
        }
    ]

    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${f1})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '100vh',
                    display: 'grid'
                }}
            >
                <Box paddingTop={8} paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center"  >
                <Box sx={{ gridColumn: { md: "4 / span 6", xs: "span 12" } }} textAlign="center"  >
                        <Box paddingX={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" }, backgroundColor: "#67c5d78c", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }} textAlign="center"  >
                                <Typography color="black" fontSize={25} sx={{ fontWeight: 'bold', color: "white" }}  >AUTORIDADES CONTABLES VIGENTES</Typography>
                            </Box>
                        </Box>
                        <Box paddingX={2} paddingBottom={2} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center" alignItems="center"  >
                            <Box sx={{ gridColumn: { md: "span 12", xs: "span 12" }, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }}  >
                                <DataGrid
                                    rowHeight={30}
                                    disableRowSelectionOnClick={true}
                                    rows={listadoAutoridades}
                                    columns={columns}
                                    getRowId={rows => rows.Id}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 20,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[15]}
                                    components={{
                                        Toolbar: GridToolbar, // Utiliza el toolbar predeterminado de Material-UI
                                    }}
                                    localeText={{ toolbarFilters: 'Buscar', toolbarDensity: 'Vista', noRowsLabel: "" }}
                                    sx={{
                                        height: "512px",
                                        '.MuiDataGrid-columnHeaderTitle': {
                                            fontWeight: 'bold',
                                            fontSize: '0.75rem'
                                        },
                                        '.MuiDataGrid-cell': {
                                            fontSize: '0.75rem',
                                            backgroundColor: "#ffffff78"
                                        },
                                        '.MuiDataGrid-filler': {
                                            display: 'none'
                                        }
                                        
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </>
    )
}

export default BandejaAC