import React from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { useLocation } from 'react-router-dom';
import Topbar from "./static/topbar";
import Footer from "./static/footer";
import VerNotificacion from "./appNotificaciones/verNotificacion";
import BandejaNotificacion from "./appNotificaciones/bandejaNotificacion";
import BandejaRadioaficionado from "./appRadioaficionado/bandejaRadioAfiocionado";
import BandejaAC from "./appAutoridadesContables/bandejaAC";
import BandejaHomologacion from "./appHomologacion/bandejaHomologacion";
import FormasRequisitos from "./appFormasRequisitos/formasRequisitos";

function App() {
  const location = useLocation();

  return (
    <>
      <CssBaseline />
      <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {location.pathname === "/verNotificacion" ? <></>:  <Topbar />}
        <main className="content">
          <Routes>
            <Route path="/verNotificacion" element={<VerNotificacion />} />
            <Route path="/BandejaNotificacion" element={<BandejaNotificacion />} />
            <Route path="/BandejaRadioaficionado" element={<BandejaRadioaficionado />} />
            <Route path="/BandejaAC" element={<BandejaAC />} />
            <Route path="/BandejaHM" element={<BandejaHomologacion />} />
            <Route path="/FormasRequisitos" element={<FormasRequisitos />} />
          </Routes>
        </main>
        {location.pathname === "/verNotificacion" ? <></>: <Footer />}
      </div>
    </>
  )
}

export default App;
