import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Box, Typography, Container, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';
import Logo1 from '../img/logoConatel.png'
import axios from 'axios';

const direccionConsultas = 'https://api-notificaciones.conatel.gob.hn/api/Notificaciones'
//const direccionConsultas = 'http://localhost:5000/api/Notificaciones'

const VerNotificacion = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const Tipo = queryParams.get('T');
    const [NumeroNotificacion, setNumeroNotificacion] = useState(queryParams.get('N'))
    const [Expediente, setExpediente] = useState("")
    const [Folio, setFolio] = useState("")
    const [Fecha, setFecha] = useState("")
    const [FechaNotificado, setFechaNotificado] = useState("")
    const [Resolucion, setResolucion] = useState("")
    const [ListadoApoderados, setListadoApoderados] = useState([])
    const [GeneroComision, setGeneroComision] = useState([])
    const [NombreComisionado, setNombreComisionado] = useState([])
    const [Delegacion, setDelegacion] = useState("")
    const [NombreArchivo, setNombreArchivo] = useState([])
    //const NumeroNotificacion = queryParams.get('N');
    let MostrarTipo = ""

    const ObtenerDatos = async () => {
        const values = {
            'NumeroNotificacion': NumeroNotificacion,
            'Tipo': Tipo
        }
        const Datos = await axios.post(direccionConsultas + '/ObtenerDatos', values)
        console.table(Datos.data)
        setListadoApoderados(Datos.data)
        setFolio(Datos.data[0].Folio)
        setExpediente(Datos.data[0].Expediente)
        setGeneroComision(Datos.data[0].Sexo)
        setNombreArchivo(Datos.data[0].ImagenNombre)
        setNombreComisionado(Datos.data[0].NombreComisionado)
        setDelegacion(Datos.data[0].Delegacion)
        const opcionesFecha = {
            weekday: 'long',
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            localeMatcher: 'best fit'
        };
        
        if (Tipo === "1") {
            const FechaOriginal = new Date(Datos.data[0].FechaProvidencia)
            const fechaBase = Datos.data[0].SistemaFecha
            const fechaLocal = new Date(fechaBase);
            fechaLocal.setHours(fechaLocal.getHours() + 6)
            const fechaFormateada = format(fechaLocal, "eeee, dd 'de' MMMM 'de' yyyy 'a las' HH:mm:ss a", { locale: es });
            FechaOriginal.setDate(FechaOriginal.getDate() + 1)
            setFecha(FechaOriginal.toLocaleDateString('es-ES', opcionesFecha))
            setFechaNotificado(fechaFormateada)       
        }
        if(Tipo === "2"){
            const FechaOriginal = new Date(Datos.data[0].FechaResolucion)
            const fechaBase = Datos.data[0].FechaNotificacion
            const fechaLocal = new Date(fechaBase);
            fechaLocal.setHours(fechaLocal.getHours() + 6)
            const fechaFormateada = format(fechaLocal, "eeee, dd 'de' MMMM 'de' yyyy 'a las' HH:mm:ss a", { locale: es });
            FechaOriginal.setDate(FechaOriginal.getDate() + 1)
            setFecha(FechaOriginal.toLocaleDateString('es-ES', opcionesFecha))
            setFechaNotificado(fechaFormateada)   
            setResolucion(Datos.data[0].Resolucion) 
        }
        if(Tipo === "3"){
            const FechaOriginal = new Date(Datos.data[0].FechaNotificado)
            const fechaBase = Datos.data[0].SistemaFecha
            const fechaLocal = new Date(fechaBase);
            fechaLocal.setHours(fechaLocal.getHours() + 6)
            const fechaFormateada = format(fechaLocal, "eeee, dd 'de' MMMM 'de' yyyy 'a las' HH:mm:ss a", { locale: es });
            FechaOriginal.setDate(FechaOriginal.getDate() + 1)
            setFecha(FechaOriginal.toLocaleDateString('es-ES', opcionesFecha))
            setFechaNotificado(fechaFormateada)   
            setResolucion(Datos.data[0].Resolucion) 
        }
    }

    useEffect(() => {
        if (NumeroNotificacion !== "")
            ObtenerDatos();
    }, [NumeroNotificacion])



    switch (Tipo) {
        case '1':
            MostrarTipo = "PROVIDENCIA"
            break;
        case '2':
            MostrarTipo = "RESOLUCIÓN"
            break;
        case '3':
            MostrarTipo = "REQUERIMIENTO"
            break;
        case '4':
            MostrarTipo = "INFORME TÉCNICO"
            break;
        default:
            MostrarTipo = ""
    }


    const descargarArchivo = async () => {
        try {
            const response = await axios.get(direccionConsultas + '/ObtenerArchivo?not=' + NumeroNotificacion + '&T=' + Tipo, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', NombreArchivo);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    };

    return (
        <>
            <Container>
                <Box marginTop={5} display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" sx={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", borderRadius: "10px" }} >
                    <Box sx={{ gridColumn: "span 12" }} padding={2} >
                        <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                            <Box sx={{ gridColumn: "span 12" }} padding={2} >
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                                    <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                        <img src={Logo1} alt="Descripción de la imagen" width="360px" height="100px" />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                            <Box sx={{ gridColumn: "span 12" }} padding={2} >
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                                    <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                        <Typography fontWeight="bold" fontSize={18}>NOTIFICACIÓN DE {MostrarTipo} {NumeroNotificacion}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                            <Box sx={{ gridColumn: "span 12" }} padding={2} >
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                                    <Box sx={{ gridColumn: "span 12", textAlign: "center" }} >
                                        <hr />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                            <Box sx={{ gridColumn: "span 12" }} padding={2} >
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                                    <Box sx={{ gridColumn: "span 12" }} >
                                        <Typography fontSize={14} sx={{ textAlign: "justify" }}>{GeneroComision === "Femenino" ? "LA INFRASCRITA SECRETARIA" : "EL INFRASCRITO SECRETARIO"} {Delegacion === "Si" ? "POR DELEGACION": "GENERAL"} DE LA COMISION NACIONAL DE TELECOMUNICACIONES, EN APLICACION AL DECRETO LEGISLATIVO 266-2013 QUE CONTIENE LA LEY PARA OPTIMIZAR LA ADMINISTRACIÓN PÚBLICA, MEJORAR LOS SERVICIOS A LA CIUDADANÍA Y FORTALECIMIENTO DE LA TRANSPARENCIA EN EL GOBIERNO”, Y REFORMA LA LEY DE PROCEDIMIENTO ADMINISTRATIVO, EN SUS ARTICULOS 87 Y 88.</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                            <Box sx={{ gridColumn: "span 12" }} padding={2} >
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                                    <Box sx={{ gridColumn: "span 12", textAlign: "left" }} >
                                        <Typography fontSize={14} >POR ESTE MEDIO, PROCEDE A NOTIFICAR EN LEGAL Y DEBIDA FORMA A:</Typography>
                                    </Box>
                                </Box>
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                                    <Box sx={{ gridColumn: "span 12", textAlign: "left" }} >
                                        <TablaApoderados ListadoApoderados={ListadoApoderados} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                            <Box sx={{ gridColumn: "span 12" }} padding={2} >
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                                    <Box sx={{ gridColumn: "span 12" }} >
                                        <Typography fontSize={14} sx={{ textAlign: "justify" }}> {Tipo !== "3" && Tipo !== "4" ? "DE LA" : "DEL"} {MostrarTipo} <b>{Resolucion}</b>  DE FECHA: <b> {Fecha} </b>QUE CORRE AGREGADA {Tipo !== "3" ? "AL FOLIO" : ""} <b>{Folio}</b>  DEL EXPEDIENTE NÚMERO <b>{Expediente}</b>. SE ADJUNTA LA RESOLUCION ESCANEADA, TENGASE POR NOTIFICADO AL ABOGADO (A) QUEDANDO OBLIGADO A DAR FIEL CUMPLIMIENTO CON LO ORDENADO EN LA MISMA.</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                            <Box sx={{ gridColumn: "span 12" }} padding={2} >
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                                    <Box sx={{ gridColumn: "span 12" }} >
                                        <Typography fontSize={14} sx={{ textAlign: "center" }}>NOTIFICACION CON FECHA {FechaNotificado}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" justifyContent="center">
                            <Box sx={{ gridColumn: "span 12" }} padding={2} textAlign="center"> {/* Agrega textAlign="center" aquí */}
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))">
                                    <Box sx={{ gridColumn: "span 12" }}>
                                        <Button variant="outlined" onClick={descargarArchivo} startIcon={<DownloadIcon />} sx={{ textAlign: 'center' }}> {/* Agrega textAlign: 'center' aquí */}
                                            Descargar
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                            <Box sx={{ gridColumn: "span 12" }} padding={2} >
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                                    <Box sx={{ gridColumn: "span 12" }} >
                                        <Typography fontSize={14} fontWeight="bold" sx={{ textAlign: "center" }}>{NombreComisionado}</Typography>
                                    </Box>
                                </Box>
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, minmax(0,1fr))" >
                                    <Box sx={{ gridColumn: "span 12" }} >
                                        <Typography fontSize={14} sx={{ textAlign: "center" }}>{GeneroComision === "Femenino" ? "SECRETARIA" : "SECRETARIO"} {Delegacion === "Si" ? "POR DELEGACION CONATEL" : "GENERAL CONATEL"} </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>
    )
}

const TablaApoderados = (props) => {
    return (
        <>
            <TableContainer >
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" scope="row">Abogado</TableCell>
                            <TableCell></TableCell>
                            <TableCell>Operador</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.ListadoApoderados.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ fontWeight: "bold" }}>{row.NombreSolicitante}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>EN SU CONDICIÓN DE: {row.Condicion === "null" ? row.Condicion : "APODERADO(A) LEGAL"} DE</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>({row.CodigoOperador}) {row.Operador}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};



export default VerNotificacion